import React, {useState, useEffect} from "react";
import {Home} from './pages/home/Home';
import {NavigationMenu} from "./components/NavigationMenu";

function App() {
    // Load lists from localStorage or initialize with an empty array
    const [lists, setLists] = useState(() => {
        const savedLists = localStorage.getItem('lists');
        return savedLists ? JSON.parse(savedLists) : [];
    });

    // Load selectedListId from localStorage or initialize with null
    const [selectedListId, setSelectedListId] = useState(() => {
        const savedSelectedListId = localStorage.getItem('selectedListId');
        return savedSelectedListId ? JSON.parse(savedSelectedListId) : null;
    });

    // Save lists to localStorage whenever the lists state changes
    useEffect(() => {
        localStorage.setItem('lists', JSON.stringify(lists));
    }, [lists]);

    // Save selectedListId to localStorage whenever it changes
    useEffect(() => {
        localStorage.setItem('selectedListId', JSON.stringify(selectedListId));
    }, [selectedListId]);

    const handleAddList = (listName) => {
        if (!listName.trim()) return;
        const newList = {id: Math.random(), name: listName, todos: []};
        setLists(prevLists => [...prevLists, newList]);
        setSelectedListId(newList.id);
    };

    const handleDeleteList = (listId) => {
        setLists(lists.filter(list => list.id !== listId));
        if (selectedListId === listId) setSelectedListId(null);
    };

    const handleSelectList = (listId) => {
        setSelectedListId(listId);
    };

    return (
        <>
            <NavigationMenu
                lists={lists}
                setLists={setLists}
                onAddList={handleAddList}
                onSelectList={handleSelectList}
                onDeleteList={handleDeleteList}
            />
            <Home
                lists={lists}
                selectedListId={selectedListId}
                setLists={setLists}
            />
        </>
    );
}

export default App;
