import React, {useState} from "react";
import "./NavigationMenu.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/free-solid-svg-icons";
import {ListMenu} from "./ListMenu";

export const NavigationMenu = ({lists, onAddList, onSelectList, onDeleteList}) => {
    const [showListMenu, setShowListMenu] = useState(false);

    return (
        <>
            <div className="navigation-menu">
                <div className="navigation-menu-item">EasyDone</div>
                <FontAwesomeIcon onClick={() => setShowListMenu(true)} className="menu-icon" icon={faBars}/>
            </div>
            {showListMenu && (
                <ListMenu
                    lists={lists}
                    setShowListMenu={setShowListMenu}
                    onAddList={onAddList}
                    onSelectList={onSelectList}
                    onDeleteList={onDeleteList}
                />
            )}
        </>
    );
};
