import React, {useState} from "react";
import "./ListMenu.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faTrash, faXmark} from "@fortawesome/free-solid-svg-icons";

export const ListMenu = ({lists, setShowListMenu, onAddList, onSelectList, onDeleteList}) => {
    const [newListName, setNewListName] = useState('');

    const handleAddList = (e) => {
        e.preventDefault();
        if (newListName.trim()) {
            onAddList(newListName.trim());
            setNewListName('');
        }
    };

    return (
        <div className="list-menu">
            <div className="close-menu-icon" onClick={() => setShowListMenu(false)}>
                <FontAwesomeIcon icon={faXmark}/>
            </div>
            <div className="list-menu-container">
                {lists.map((list) => (
                    <div key={list.id} className="list-menu-item">
                        <span className="list-name" onClick={() => onSelectList(list.id)}>{list.name}</span>
                        <FontAwesomeIcon icon={faTrash} className="list-delete" onClick={() => onDeleteList(list.id)}/>
                    </div>
                ))}
                <form onSubmit={handleAddList} className="list-menu-item add-list">
                    <input
                        type="text"
                        placeholder="New List Name"
                        value={newListName}
                        onChange={(e) => setNewListName(e.target.value)}
                    />
                    <button type="submit"><FontAwesomeIcon icon={faPlus}/></button>
                </form>
            </div>
        </div>
    );
};
