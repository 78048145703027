import React from "react";
import {TodoList} from "../../components/TodoList";
import {AddStep} from "../../components/AddStep";
import {TodoHeader} from "../../components/TodoHeader";
import './home.css';

export const Home = ({lists, selectedListId, setLists}) => {
    const selectedList = lists.find(list => list.id === selectedListId) || {};

    const updateTodosForSelectedList = (updatedTodos) => {
        setLists(lists.map(list =>
            list.id === selectedListId ? {...list, todos: updatedTodos} : list
        ));
    };

    const updateListName = (newName) => {
        setLists(lists.map(list =>
            list.id === selectedListId ? {...list, name: newName} : list
        ));
    };

    const handleAddTodo = (task) => {
        const newTodo = {id: Math.random(), task, completed: false, edit: false};
        const updatedTodos = [...selectedList.todos, newTodo];
        updateTodosForSelectedList(updatedTodos);
    };

    const handleDeleteTodo = (id) => {
        const updatedTodos = selectedList.todos.filter(todo => todo.id !== id);
        updateTodosForSelectedList(updatedTodos);
    };

    const handleEditTodo = (id) => {
        const updatedTodos = selectedList.todos.map(todo =>
            todo.id === id ? {...todo, edit: !todo.edit} : todo
        );
        updateTodosForSelectedList(updatedTodos);
    };

    const handleSaveTodo = (task, id) => {
        const updatedTodos = selectedList.todos.map(todo =>
            todo.id === id ? {...todo, task, edit: false} : todo
        );
        updateTodosForSelectedList(updatedTodos);
    };

    return (
        <div className="home-screen">
            {selectedListId ? (
                <div className="task-container">
                    <TodoHeader
                        listName={selectedList.name}
                        updateListName={updateListName}
                    />
                    <div className="todo-list-container">
                        <AddStep addTodo={handleAddTodo}/>
                        <TodoList
                            todos={selectedList.todos || []}
                            setTodos={updateTodosForSelectedList}
                            deleteTodo={handleDeleteTodo}
                            editTodo={handleEditTodo}
                            saveTodo={handleSaveTodo}
                        />
                    </div>
                </div>
            ) : (
                <div>Select a list to get started!</div>
            )}
        </div>
    );
};
