import React, {useState} from 'react';
import './AddStep.css'
import {Button} from "./Button";

export const AddStep = ({addTodo}) => {
    const [value, setValue] = useState('');
    const handleSubmit = e => {
        e.preventDefault();

        if(!value) {
            return;
        }

        addTodo(value);
        setValue('');
    }

    return (
        <form onSubmit={handleSubmit} className={'todo-add-step-container'}>
            <input placeholder='Add a step'
                   type='text'
                   value={value}
                   onChange={(e) => setValue(e.target.value)}
            />
            <Button
                onClick={handleSubmit}
                status={'primary'}
                text={'+'}
            />
        </form>)
}
