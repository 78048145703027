import React, {useState} from "react";
import "./TodoHeader.css";
import {Button} from "./Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit} from "@fortawesome/free-solid-svg-icons";

export const TodoHeader = ({listName, updateListName}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [header, setHeader] = useState(listName);

    const handleSave = () => {
        if (header.trim()) {
            updateListName(header);
            setIsEditing(false);
        }
    };

    const handleEditClick = () => {
        setIsEditing(true);
    };

    return (
        <div>
            {isEditing
                ? <form
                    onSubmit={handleSave}
                    className={'todo-header-container'}
                >
                    <input
                        placeholder="Enter list name"
                        type="text"
                        value={header}
                        onChange={(e) => setHeader(e.target.value)}
                    />
                    <Button onClick={handleSave} text="Save" type="primary"/>
                </form>
                : <div className={'todo-header-container header-line'}>
                    <h2 className={'todo-header-text'}>{listName}</h2>
                    <div className={'action-buttons'}>
                        <span className="edit-icon" onClick={handleEditClick}>
                            <FontAwesomeIcon icon={faEdit}/>
                        </span>
                    </div>
                </div>
            }
        </div>
    );
};
