import React from "react";
import "./Todo.css"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit, faGripLines, faTrash} from '@fortawesome/free-solid-svg-icons'; // Import a specific icon

export const Todo = ({
                         todo,
                         index,
                         toggleComplete,
                         deleteTodo,
                         editTodo
                     }) => {
    return (
        <li className={`todo-container`}>
            <span className="drag-handle"> <FontAwesomeIcon icon={faGripLines}/></span> {/* This is the handle */}
            <div className={`${todo.completed && 'completed'} todo-text`} onClick={() => toggleComplete(todo.id)}>
                {index + 1}. {todo.task}
            </div>
            <span className="edit-icon" onClick={() => editTodo(todo.id)}>
                <FontAwesomeIcon icon={faEdit}/>
            </span>
            <span className="delete-icon" onClick={() => deleteTodo(todo.id)}>
                <FontAwesomeIcon icon={faTrash}/>
            </span>
        </li>
    )
}
